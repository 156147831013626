
export const cards = [
    {
        id: 1,
        title: 'INFO 1300',
        name: 'Celeste Naughton',
    },
    {
        id: 2,
        title: 'CS 3410',
        name: 'Angelica Schell',
    },
    {
        id: 3,
        title: 'INFO 1300',
        name: 'Justin Eburuoh',
    },
    {
        id: 4,
        title: 'INFO 4430',
        name: 'Pryor Simon ',
    },
    {
        id: 5,
        title: 'INFO 1300',
        name: 'Kelly Ankoue',
    },
    {
        id: 6,
        title: 'CS 2800',
        name: 'Clarence Dagins',
    },
    {
        id: 6,
        title: 'CS 1110',
        name: 'Abdul Raafai Asim',
    },
    {
        id: 7,
        title: 'CS 1110',
        name: 'Alvaro Deras',
    },
    {
        id: 9,
        title: 'CS 1110',
        name: ' Fiifi Botchway',
    },
    {
        id: 10,
        title: 'CS 1110',
        name: 'Daniela Carmona Lopez Mestre',
    },
    {
        id: 11,
        title: 'CS 3119',
        name: 'Pedro Pontes Garcia',
    },
    {
        id: 12,
        title: 'CS 4701',
        name: 'Amirah Ricks',
    },
    {
        id: 13,
        title: 'CS 2110',
        name: 'Minhaj',
    },
    {
        id: 14,
        title: 'CS 2110',
        name: 'Brianna Liu',
    },
    {
        id: 15,
        title: 'CS 1110',
        name: 'Brian Sanchez',
    },
    {
        id: 16,
        title: 'CS 2800',
        name: 'Charles Liggins',
    },
    {
        id: 17,
        title: 'CS 1110',
        name: 'Wei-Che (Harry) Lin',
    },
    {
        id: 18,
        title: 'INFO 3450',
        name: 'Shania Cabrera',
    },

   
  
   
   
    
    

 



    



];